import React from "react"
import servicios_destacados from "./../assets/img/servicios-destacados.png";
import mi_osfatun from "./../assets/img/mi-osfatun.png"
import urgencias from "./../assets/img/ambulacia.png"
import medico from "./../assets/img/medico.png"
import odontoligia from "./../assets/img/odontoligia.png"
import habitacion from "./../assets/img/habitacion.png"
import descuentos_farmacias from "./../assets/img/descuentos-farmacias.png"
import descuentos_opticas from "./../assets/img/descuentos-opticas.png"
import cobertura_exterior from "./../assets/img/cobertura-exterior.png"
import cobertura_nacional from "./../assets/img/cobertura-nacional.png"
import Servicio from "./Servicio";

const Servicios = () => {
  return (
    <>
      <section className="servicios-destacados">
        <img src={servicios_destacados} alt="servicios destacados" />
      </section>
      <section className="container">
        <div className="columns is-multiline is-mobile is-justify-content-center	">
          <div className="column is-6-mobile is-4-desktop">
            <Servicio image={mi_osfatun} alt={"MI OSFATUN"}>MI OSFATUN</Servicio>
          </div>
          <div className="column is-6-mobile is-4-desktop">
            <Servicio image={urgencias} alt={"urgencias"}>URGENCIAS <br /> 24HS</Servicio>
          </div>
          <div className="column is-12-mobile is-4-desktop">
            <Servicio image={medico} alt={"medico"}>MÉDICO A DOMICILIO</Servicio>
          </div>
          <div className="column is-6-mobile is-4-desktop">
            <Servicio image={odontoligia} alt={"odontoligia"}>ODONTOLOGÍA GENERAL</Servicio>
          </div>
          <div className="column is-6-mobile is-4-desktop">
            <Servicio image={habitacion} alt={"habitacion"}>HABITACIÓN INDIVIDUAL</Servicio>
          </div>
        </div>
      </section>
      <section className="container mt-6">
        <div className="columns is-gapless col-descuentos is-multiline is-mobile is-vcentered">
          <div className="column is-1 is-hidden-mobile"></div>
          <div className="column is-6-mobile mt-3">
            <img src={descuentos_farmacias} alt="" />
          </div>
          <div className="column is-6-mobile mt-3">
            <img src={descuentos_opticas} alt="" />
          </div>
          <div className="column is-6-mobile mt-3">
            <img src={cobertura_exterior} alt="" />
          </div>
          <div className="column is-6-mobile mt-3">
            <img src={cobertura_nacional} alt="" />
          </div>
          <div className="column is-1 is-hidden-mobile"></div>
        </div>
      </section>
    </>
  )
}
export default Servicios