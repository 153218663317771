import axios from "axios"
import React, { useEffect, useState } from "react"
import necesidad from "./../assets/img/necesidad.png"
import geography from "./../assets/json/geography.json";
import Loading from "./Loading";
import Swal from 'sweetalert2'

const initialForm = {
  fullname: "",
  email: "",
  phone: "",
  province: "",
  city: "",
  message: ""
}
const Formulario = () => {
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [form, setForm] = useState(initialForm)

  const [loading, setLoading] = useState(false)


  useEffect(() => {
    getProvince()
  }, [])

  useEffect(() => {
    // cuando se selecciona una provincia
    setForm({ ...form, city: "" })
    getCity()
  }, [form.province])

  const getProvince = () => {
    const dataProvince = []
    geography.forEach(({ id, nombre }) => {
      dataProvince.push({ id, name: nombre })
    })
    setProvinces(dataProvince)
  }

  const getCity = () => {
    const dataCity = []
    geography.forEach(({ nombre, ciudades }) => {
      if (form.province === nombre) {
        ciudades.forEach(({ id, nombre }) => {
          dataCity.push({ id, name: nombre })
        })
      }
    })
    setCities(dataCity)
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (true) { // validaciones
      setLoading(true)
      try {
        // Creaccion del Contacto
        const contact = await createContact()
        // Creaccion del ticket
        const ticket = await createTicket(contact.data.contact.id)

        if (ticket)
          Swal.fire({
            title: 'Envio exitoso!!',
            text: '',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          })
        setLoading(false)
      } catch (err) {
        Swal.fire({
          title: 'Error!!',
          text: 'Error al enviar, intentelo de nuevo',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        })
        console.log(err)
        setLoading(false)
        setForm(initialForm)
      }
    }
  }

  const createContact = () => {
    console.log('Creating contact...')
    const contact = new FormData()
    contact.append('type', 'potencial afiliado')
    contact.append('fullname', form.fullname)
    contact.append('email', form.email)
    contact.append('phone', form.phone)
    contact.append('province', form.province)
    contact.append('city', form.city)
    return axios.post('https://api.osfatun.com.ar/api/contacts', contact)
  }

  const createTicket = (contact) => {
    const ticket = new FormData()
    ticket.append('origin', 'web')
    ticket.append('contact', contact)
    ticket.append('departament', 1) // ventas
    ticket.append('type', 'received')
    ticket.append('content', form.message)
    return axios.post('https://api.osfatun.com.ar/api/tickets', ticket)
  }

  return (
    <form className="formulario" onSubmit={handleSubmit}>
      <div className="columns is-multiline">
        <div className="column is-12-mobile is-5-desktop"></div>
        <div className="column is-12-mobile is-7-desktop has-text-centered form-enviar">

          En OSFATUN contamos con planes que se adaptan a cada
          <span className="necesidad">
            <img src={necesidad} alt="servicios destacados" />
          </span>.
          <br />
          Dejanos tus datos o escribinos por WhatsApp para poder encontrar el mejor para vos.
          <div className="columns is-multiline is-mobile mt-2">
            {loading && <section className="column is-12"><Loading /></section>}
            {!loading &&
              <>
                <div className="column is-12-mobile is-6-desktop">
                  <input name="fullname" onChange={handleChange} type="text" value={form.fullname} placeholder="Nombre y Apellido" />
                  <input name="email" onChange={handleChange} type="email" value={form.email} placeholder="Mail" />
                  <input name="phone" onChange={handleChange} type="text" value={form.phone} placeholder="Teléfono" />
                  <select className="select is-fullwidth input" name="province" onChange={handleChange} defaultValue={form.province}>
                    <option value={""}>Seleccionar provincia</option>
                    {
                      provinces.length > 0 &&
                      provinces.map(({ name, id }) => <option key={id} name={name}>{name}</option>)
                    }
                  </select>
                  <select className="select is-fullwidth input mt-2" name="city" onChange={handleChange} defaultValue={form.city}>
                    <option value={""}>Seleccionar ciudad</option>
                    {
                      cities.length > 0 &&
                      cities.map(({ name, id }) => <option key={id} name={name}>{name}</option>)
                    }
                  </select>

                </div>
                <div className="column is-12-mobile is-6-desktop">
                  <textarea name="message" onChange={handleChange} value={form.message} placeholder="Mensaje (opcional)"></textarea>
                </div>
                <div className="column is-gapless-columns">
                  <button type="submit">Enviar</button>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </form>
  )
}
export default Formulario
