import React from "react"
import Logo from "./../assets/img/whatsapp.png"
const Whatsapp = () => {
  const url = "http://wa.me/541131632066"

  return (
    <>
      <a rel="noreferrer" href={url} target="_blank" className="whatsapp">
        <img src={Logo} alt="whatsapp" />
      </a>
    </>
  )
}

export default Whatsapp
