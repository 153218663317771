import React from "react"
import Footer from "../components/Footer"
import Formulario from "../components/Formulario"
import Header from "../components/Header"
import Servicios from "../components/Servicios"
import Whatsapp from "../components/Whatsapp"
const Home = () => {
  return (
    <>
      <main className="fondoPrincipal">
        <Header />
        <section className="fondoSection">
          <Formulario />
        </section>
        <Servicios />
        <Footer />
        <Whatsapp />
      </main>
    </>
  )
}
export default Home